$flex: flex;
$center: center;
$league-container-width: 70vw;

.liveContainer__header {
  background-color: #26a380;
}

.liveContainer__header__content {
  display: $flex;
  margin-left: 2rem;
  flex-direction: row;
  align-items: $center;
}

.live__dot {
  height: 12px;
  width: 12px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.live__title {
  margin: 0.5rem;
  font-size: x-large;
  font-weight: 500;
}

.liveContainer__body {
  display: $flex;
  justify-content: $center;
  background-color: #EEEEEE;
  min-height: 100vh;
}

.liveContainer__body__scores {
  display: $flex;
  flex-direction: column;
  align-items: $center;
}

.league__selector {
  margin-top: 1rem;
}

.liveContainer__body__leagueContainer {
  width: $league-container-width;
  margin: 2rem;
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 0.15rem;
    width: 100%;
  }
  tr {
    height: 3rem;
    background-color: #FFFFFF;
    cursor: pointer;
  }
}

.leagueNameContainer {
  height: 3rem;
  font-size: x-large;
  text-align: $center;
  background-color: #222831;
  color: white;
}

.leagueName {
  line-height: 3rem;
}

#leagueSelect {
  font-family: 'Poppins';
}

.liveScore {
  width: 12%;
}

.liveScore__homeTeam {
  text-align: right;
  width: 44%;
}

.liveScore__awayTeam {
  text-align: left;
  width: 44%;
}

.liveScoreContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.noGames {
  text-align: $center;
}

.state {
  min-width: 50px;
}

.home__name__logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.away__name__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.homeTeam {
  text-align: right;
  margin-right: 0.5rem;
}

.awayTeam {
  margin-left: 0.5rem;
}

.MuiSelect-select:focus {
  background: none !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #222831 !important;
}

@media all and (min-width:0px) and (max-width: 650px) {
  .headerContainer {
    height: 3rem;
  }

  .header__title h2 {
    font-size: x-large;
  }

  table {
    font-size: small;
  }

  .liveScore {
    width: 15%;
  }
  
  .liveScore__homeTeam {
    width: 42.5%;
  }
  
  .liveScore__awayTeam {
    width: 42.5%;
  }

  .live__title{
    font-size: inherit;
  }

  .leagueNameContainer {
    font-size: inherit;
  }

  .noGames {
    font-size: small;
  }

  .liveContainer__body__leagueContainer {
    min-width: 90vw;
    margin: 1rem;
  }
}